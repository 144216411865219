<script>
	import Icon from '@iconify/svelte'
	import { cn } from '@lib/utils'
	export let classes = 'h-4 w-4 text-white'
	export { classes as class }
</script>

<!-- <Icon icon="feather:search" class={classes} /> -->

<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class={classes}
	><path
		fill="none"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="2"
		d="M4 8h16M4 16h16"
	/></svg
> -->

<svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 22 14"
	class={cn('h-6 w-6 text-white', classes)}
>
	<path
		id="manage_search_FILL0_wght300_GRAD0_opsz20"
		d="M116-686.022v-1.569h11.337v1.569Zm0-5.3v-1.569h5.477v1.569Zm0-5.3V-698.2h5.477v1.569ZM136.878-686l-4.647-4.6a5.441,5.441,0,0,1-1.445.733,5.189,5.189,0,0,1-1.656.262,5.067,5.067,0,0,1-3.7-1.517,4.994,4.994,0,0,1-1.531-3.686,4.968,4.968,0,0,1,1.533-3.679A5.092,5.092,0,0,1,129.14-700a5.052,5.052,0,0,1,3.7,1.515,4.989,4.989,0,0,1,1.524,3.675,5.166,5.166,0,0,1-.263,1.654,5.067,5.067,0,0,1-.746,1.431l4.647,4.6L136.878-686Zm-7.748-5.178a3.551,3.551,0,0,0,2.593-1.056,3.479,3.479,0,0,0,1.068-2.57,3.479,3.479,0,0,0-1.068-2.57,3.551,3.551,0,0,0-2.593-1.056,3.551,3.551,0,0,0-2.593,1.056,3.479,3.479,0,0,0-1.068,2.57,3.479,3.479,0,0,0,1.068,2.57A3.551,3.551,0,0,0,129.13-691.177Z"
		transform="translate(-116.001 699.999)"
		fill="#fff"
	/>
</svg>
