<script lang="ts">
	import { clickOutside, highlightText, slug } from '@lib/utils'
	import DropdownPanel from './DropdownPanel.svelte'
	import Menu from './icons/Menu.svelte'
	import X from './icons/X.svelte'
	import ChevronRight from './icons/ChevronRight.svelte'
	import Search from './icons/Search.svelte'
	import { products } from '@lib/products'
	import { tick } from 'svelte'
	import config from 'src/config'

	let dropdownOpen = false
	let showSearch = false
	let q = ''
	let mobileEl: HTMLElement

	function clearSearch() {
		q = ''
		showSearch = false
	}

	// async function openSearch() {
	// 	console.log('openSearch')
	// 	showSearch = true
	// 	await tick()
	// 	document.getElementById('nav-search-input')?.focus()
	// 	checkBlur()
	// }

	async function openMenu() {
		dropdownOpen = true
		await tick()
		document.getElementById('nav-search-input')?.focus()
		checkBlur()
	}
	function closeMenu(e) {
		dropdownOpen = false
		checkBlur()
		clearSearch()
	}

	function searchOnKeydown(e) {
		// refresh(true, 'searchOnKeydown')
		// setUrlParams('searchOnKeydown')
	}

	function checkBlur() {
		try {
			if (!mobileEl?.checkVisibility()) return
			// console.log('checkblur mobilemenu')
			if (dropdownOpen) {
				document.getElementById('main')?.classList.add('blur-sm')
				document.body?.classList.add('body-no-scroll')
			} else {
				document.getElementById('main')?.classList.remove('blur-sm')
				document.body?.classList.remove('body-no-scroll')
			}
		} catch (e) {
			console.warn(e)
		}
	}

	$: results =
		q && q.length > 1
			? products.filter((i) => i.sku?.toLowerCase().includes(q.toLowerCase()))
			: []
</script>

<div
	bind:this={mobileEl}
	class="relative z-50 flex items-center lg:hidden"
	use:clickOutside
	on:outclick={closeMenu}
>
	{#if !dropdownOpen}
		<button aria-label="Open Menu" on:click={openMenu}>
			<Menu class="ml-4" />
		</button>
	{:else}
		<button aria-label="Close Menu" on:click={closeMenu}>
			<X class="ml-4 h-6 w-6" />
		</button>
	{/if}

	<DropdownPanel
		class="top-[47px] space-y-0 border-0 px-0 py-0"
		open={dropdownOpen}
	>
		<!-- {#if !showSearch} -->
		<!-- <div class="flex flex-col text-white">
			<button
				class="inline-flex h-[48px] items-center space-x-2 px-4 text-left"
				on:click={openSearch}
			>
				Search
				<Search class="ml-2 h-4 w-4" />
			</button>
		</div> -->
		<!-- {/if} -->
		<!-- {#if showSearch} -->
		<form
			method="GET"
			action="/products?q={q}#products"
			class="flex w-full items-center justify-between px-4"
		>
			<Search class="mr-2 h-4 w-4" />
			<input
				id="nav-search-input"
				bind:value={q}
				on:keydown={searchOnKeydown}
				name="q"
				placeholder="Search"
				class="h-full flex-1 border-none bg-transparent p-0 py-4 text-light text-white ring-0 focus:ring-0"
			/>
			<!-- <button on:click={clearSearch}>
				<X class="h-4 w-4 text-white" />
			</button> -->
		</form>
		<div class="flex max-h-[300px] flex-col space-y-4 overflow-y-auto">
			{#each results as { sku, brand }}
				<a
					href="/products/{slug(brand)}/{sku}"
					data-astro-reload
					class="flex w-full items-center justify-between px-4 text-light"
				>
					<span>{@html highlightText(sku, q)}</span>
					<ChevronRight class="h-4 w-4 text-white" />
				</a>
			{/each}
		</div>
		<!-- {/if} -->
		<div class="flex flex-col space-y-4 overflow-y-auto px-4 py-4">
			<span class="ml-6 block text-white">Quick Links</span>
			<a
				href="/products"
				data-astro-reload
				class="flex w-full items-center text-light"
			>
				<ChevronRight class="mr-2 h-4 w-4 text-white" />
				<span>Product Catalog</span>
			</a>
			<a
				href="/about"
				data-astro-reload
				class="flex w-full items-center text-light"
			>
				<ChevronRight class="mr-2 h-4 w-4 text-white" />
				<span>About</span>
			</a>
			{#each config.quickLinks as link}
				<a
					href={link.href}
					data-astro-reload
					class="flex w-full items-center text-light"
				>
					<ChevronRight class="mr-2 h-4 w-4 text-white" />
					<span>{link.label}</span>
				</a>
			{/each}
		</div>
	</DropdownPanel>
	<!-- <div
			class="bg-black-800/80 fixed inset-0 bg-opacity-25 backdrop-blur-sm transition-opacity"
		></div> -->
</div>
